import React, { useEffect, useMemo } from 'react';
import { Box, Alert } from '@mui/material';
import { useNavigate } from 'react-router';
import Content from '../features/content/Content';
import usePresentationFlow from '../features/presentationFlow/usePresentationFlow';
import useCurrentPresentation from '../features/presentation/useCurrentPresentation';
import CategorySkeleton from '../features/category/CategorySkeleton';
import useSelectedAgency from '../features/agency/useSelectedAgency';
import useAgency from '../features/agency/useAgency';
import useEditMode from '../features/template/useEditMode';
import filterVisibleChapterContents from '../features/presentationFlow/filterVisibleChapterContents';
import usePresentationFlows from '../features/presentationFlow/usePresentationFlows';
import ContainerPresentation from '../layout/ContainerPresentation';
import CoverPage from '../features/presentationFlow/CoverPage';
import { useHeaderHeight } from '../layout/HeaderHeightProvider';

interface PresentationProps {
    exportPdf?: boolean;
}

function Presentation({ exportPdf }: PresentationProps) {
    const navigate = useNavigate();
    const selectedAgency = useAgency(useSelectedAgency()[0]);
    const [editMode] = useEditMode();
    const [currentPresentation, setCurrentPresentation] =
        useCurrentPresentation();
    const allPresentationFlows = usePresentationFlows();
    const presentationFlow = usePresentationFlow(
        currentPresentation?.presentationFlowId
    );
    const [headerHeight] = useHeaderHeight();

    useEffect(() => {
        if (currentPresentation && allPresentationFlows && !presentationFlow) {
            // Si j'ai une currentPresentation mais que le
            // PresentationFlow n'existe pas
            setCurrentPresentation(undefined);
            navigate('/mes-rendez-vous');
        }
    }, [
        currentPresentation,
        allPresentationFlows,
        presentationFlow,
        setCurrentPresentation,
        navigate,
    ]);

    useEffect(() => {
        if (currentPresentation === undefined) {
            navigate('/mes-rendez-vous');
        }
    }, [navigate, currentPresentation]);

    const filteredPresentation = useMemo(
        () =>
            filterVisibleChapterContents(
                presentationFlow,
                editMode,
                selectedAgency
            ),
        [presentationFlow, editMode, selectedAgency]
    );

    const coverPageId = presentationFlow?.coverPage?.id;

    // pas de rendu tant qu'on a pas la présentation + les agences (avec leurs customisations) chargés
    if (!presentationFlow || !selectedAgency) {
        return <CategorySkeleton pt={headerHeight} />;
    }

    return (
        <ContainerPresentation exportPdf={exportPdf}>
            {coverPageId && exportPdf && (
                <CoverPage coverPageId={coverPageId} exportPdf={exportPdf} />
            )}

            {filteredPresentation?.chapters?.collection.map((chapter: any) => (
                <React.Fragment key={chapter.id}>
                    {chapter?.chapterContents?.collection.map(
                        (chapterContent: any) => {
                            if (chapterContent.content) {
                                return (
                                    <Content
                                        key={chapterContent.id}
                                        content={chapterContent.content}
                                        exportPdf={exportPdf}
                                    />
                                );
                            }
                            return <CategorySkeleton />;
                        }
                    )}
                </React.Fragment>
            ))}
            {!filteredPresentation && <CategorySkeleton />}
            {filteredPresentation?.chapters?.collection?.length === 0 && (
                <Box className="section" mt={2}>
                    {' '}
                    {/* avoid error fullpage due to empty items */}
                    <Alert severity="error">Le déroulé est vide</Alert>
                </Box>
            )}
        </ContainerPresentation>
    );
}

export default Presentation;
